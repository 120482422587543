.center {
    text-align: center;
}
.center * {
    margin-right: auto;
    margin-left: auto;
}
.cancelCenter * {
    margin-right: 0;
    margin-left: 0;
}

/* Folktale内のabbrの下線除去 */
abbr[title] {
    text-decoration: none;
    border-bottom-width: 0;
}

.markdownH2 {
    margin: 55px 0 25px;
    padding: 20px;
    color: white;
    background: linear-gradient(to top, #035c1d, #047c28);
    border-radius: 5px;
}

.breadcrumbs,
.ninjaGameTop,
.whiteShadow {
    text-shadow: 0px 0px 1px white, 0px 0px 2px white, 0px 0px 3px white,
        0px 0px 4px white, 0px 0px 5px white, 0px 0px 5px white,
        0px 0px 5px white, 0px 0px 5px white, 0px 0px 5px white,
        0px 0px 5px white, 0px 0px 5px white, 0px 0px 5px white,
        0px 0px 5px white, 0px 0px 5px white, 0px 0px 5px white,
        0px 0px 6px white, 1px 1px 6px white, -1px 1px 6px white,
        1px -1px 6px white, -1px -1px 6px white, 2px 2px 6px white,
        -2px 2px 6px white, 2px -2px 6px white, -2px -2px 6px white,
        3px 3px 6px white, -3px 3px 6px white, 3px -3px 6px white,
        -3px -3px 6px white, 0px 0px 8px white, 1px 1px 8px white,
        -1px 1px 8px white, 1px -1px 8px white, -1px -1px 8px white,
        2px 2px 8px white, -2px 2px 8px white, 2px -2px 8px white,
        -2px -2px 8px white, 3px 3px 8px white, -3px 3px 8px white,
        3px -3px 8px white, -3px -3px 8px white, 0px 0px 10px white,
        1px 1px 10px white, -1px 1px 10px white, 1px -1px 10px white,
        -1px -1px 10px white, 2px 2px 10px white, -2px 2px 10px white,
        2px -2px 10px white, -2px -2px 10px white, 3px 3px 10px white,
        -3px 3px 10px white, 3px -3px 10px white, -3px -3px 10px white,
        3px 3px 10px white, -3px 3px 10px white, 3px -3px 10px white,
        -3px -3px 10px white;
}

.hoverScale05 {
    transition: all 0.5s !important;
    z-index: 0 !important;
}

.hoverScale05:hover {
    transform: scale(1.05, 1.05) !important;
    z-index: 100 !important;
}

/*
    font-weight
*/
.bold {
    font-weight: bold !important;
}

/*
    font-size
*/
.small {
    font-size: small !important;
}
.medium {
    font-size: medium !important;
}
.large {
    font-size: large !important;
}
.x-large {
    font-size: x-large !important;
}
.xx-large {
    font-size: xx-large !important;
}
.xxx-large {
    font-size: xxx-large !important;
}

/*
    color
*/
.red {
    color: red !important;
}
.grey {
    color: grey !important;
}

/*
    white-space
*/
.nowrap {
    white-space: nowrap !important;
}

/*
    width, height
*/
.fullWidth {
    width: 100% !important;
}
.fullHeight {
    height: 100% !important;
}

/*
    opacity
*/
.opacity0 {
    opacity: 0 !important;
}
.opacity1 {
    opacity: 1 !important;
}

/*
    display
*/
.inline-block {
    display: inline-block !important;
}

/*
    transition
*/
.t1s {
    transition: all 1s !important;
}
.t500ms {
    transition: all 500ms !important;
}

/*
    position
*/
.relative {
    position: relative !important;
}

/*
    cursor
*/
.pointer {
    cursor: pointer !important;
}
.not-allowed {
    cursor: not-allowed !important;
}

/*
    flexbox
*/
.column {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}
.flexCenter {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
.flexWrap {
    flex-wrap: wrap !important;
}

/*
    text-decoration
*/
.underline {
    text-decoration: underline !important;
}
