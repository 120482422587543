@keyframes fallSmall {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    100% {
        transform: translate(-1000px, 2000px) rotate(2000deg);
    }
}

@keyframes fall {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    100% {
        transform: translate(-1000px, 2000px) rotate(1000deg);
    }
}

@media only screen and (max-width: 600px) {
    .falling {
        animation: fallSmall 40s 1s ease-out;
    }
}

@media only screen and (min-width: 601px) {
    .falling {
        animation: fall 25s 1s ease-out;
    }
}
