.characterCommentContainer {
    display: flex;
    max-width: 600px;
    margin: auto;
}

.says {
    display: inline-block;
    position: relative;
    margin: 5px 0 0 10px;
    padding: 17px 13px;
    border-radius: 12px;
    background: antiquewhite;
}

.character-says {
    max-width: 420px;
}

.says:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 18px;
    left: -24px;
    border: 12px solid transparent;
    border-right: 12px solid antiquewhite;
}

.says p {
    margin: 0;
    padding: 0;
}

.chatting {
    height: auto;
    display: flex;
    align-items: center;
    flex: 3;
}

.userChatting {
    height: auto;
    display: flex;
    align-items: flex-start;
    flex: 3;
}

.ninjaPicContainer {
    flex: 1;
}

.ninjaPic {
    animation: 10s linear infinite ninjaCommentAnime;
    animation-delay: 1s;
    max-width: 120px;
    height: auto;
    vertical-align: top;
}
@keyframes ninjaCommentAnime {
    0% {
        transform: translate3d(0px, 0px, 0px);
    }
    2% {
        transform: translate3d(0px, -10px, 0px);
    }
    5% {
        transform: translate3d(0px, 0px, 0px);
    }
    7% {
        transform: translate3d(0px, -10px, 0px);
    }
    10% {
        transform: translate3d(0px, 0px, 0px);
    }
    100% {
        transform: translate3d(0px, 0px, 0px);
    }
}
